
@font-face {
    font-family: 'HelveticaNeueCyr-Medium';
    src: url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Medium1.eot");
    src: url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Medium1.eot?#iefix") format("embedded-opentype"), url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Medium1.woff2") format("woff2"), url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Medium1.woff") format("woff"), url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Medium1.svg#HelveticaNeueCyr-Medium") format("svg");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueCyr-Bold';
    src: url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Bold1.eot");
    src: url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Bold1.eot?#iefix") format("embedded-opentype"), url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Bold1.woff2") format("woff2"), url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Bold1.woff") format("woff"), url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Bold1.svg#HelveticaNeueCyr-Medium") format("svg");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueCyr-Light';
    src: url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Light.eot");
    src: url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Light.eot?#iefix") format("embedded-opentype"), url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Light.svg#HelveticaNeueCyr-Medium") format("svg");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }
  
  @font-face {
    font-family: 'HelveticaNeueCyr-Roman1';
    src: url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Roman1.eot");
    src: url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Roman1.eot?#iefix") format("embedded-opentype"), url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Roman1.woff2") format("woff2"), url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Roman1.woff") format("woff"), url("https://cdn.hubx.com/assets/fonts/HelveticaNeueCyr-Roman1.svg#HelveticaNeueCyr-Medium") format("svg");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
  }